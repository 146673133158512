import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

const HolidayTwentyTwentyFour = () => {
  const { themeColor } = usePageSetup({
    themeColor: "bg-holiday-red",
    navigationColor: "text-holiday-gray",
    displayGeneralForm: false,
  })
  return (
    <Layout>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Seo title="Holiday 2024" description="" />

      <section className={themeColor}>
        <Hero className="pb-clamp-14-25 pt-clamp-16-26">
          <div className="w-full mt-6">
            <div className="mx-auto lg:w-8/12 lg:pr-16">
              <h1
                className="font-normal text-headline"
                style={{ fontFamily: '"EB Garamond", serif' }}
                data-aos="stagger"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-holiday-gray": "Wishing you a season filled with joy, peace, and special moments.",
                  }),
                }}
              />
              <div data-aos="fade-up" data-aos-delay="400">
                <p className="mt-9 text-holiday-gray" style={{ fontFamily: '"EB Garamond", serif' }}>
                  We're looking back on some of the moments that make the holiday season so special.{" "}
                </p>
                <p className="mt-9 text-holiday-gray" style={{ fontFamily: '"EB Garamond", serif' }}>
                  Click the play button to see them!
                </p>
              </div>
            </div>
          </div>
        </Hero>
      </section>
      <main className="overflow-hidden">
        <section className={`relative bg-holiday-red`}>
          <div className="absolute inset-0 flex">
            <div className={`w-full bg-holiday-gray h-1/2 mt-auto`}></div>
          </div>
          <div className="container flex justify-center">
            <div className="w-full lg:w-10/12">
              <div className="shadow-md mt-7">
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <iframe
                    src="https://player.vimeo.com/video/1040579754?h=7e4af3c02b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                    title="Happy Holidays from JK Design"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="w-full h-16 bg-holiday-gray"></div>
    </Layout>
  )
}

export default HolidayTwentyTwentyFour

export const HolidayTwentyTwentyFourQuery = graphql`
  query holidayTwentyTwentyFourQuery {
    imageA: file(relativePath: { eq: "holiday/image-a.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
